<template>
  <el-card>
    <div class="vg_mb_8">
      退回时间
      <el-date-picker
        v-model="dateRange"
        class="vg_ml_8 vg_mr_8 topDateRangeSearch"
        end-placeholder="退回结束日期"
        range-separator="至"
        size="small"
        start-placeholder="退回开始日期"
        type="daterange"
        value-format="timestamp"
      />
      <el-button class="vd_export" size="small" type="primary" @click="getTableData()">查询</el-button>
      <el-button class="vd_export" icon="el-icon-refresh-right" size="small" type="info" @click="buttonRefresh()">刷新</el-button>
    </div>
    <DynamicUTable
      ref="multiTable"
      v-loading="loading"
      :columns="tableProperties"
      :need-check-box="false"
      :need-pagination="false"
      :need-search="true"
      :need-fixed-height="true"
      :tableData="tableData"
      @getTableData="getTableData"
    >
      <template v-slot:operation="scope">
        <el-link class="vg_mr_8 vg_cursor" size="mini" type="primary" @click="jumpFequ(scope.row)">查看</el-link>
        <el-link class="vg_cursor" size="mini" type="danger" @click="del(scope.row)">删除</el-link>
      </template>
    </DynamicUTable>
  </el-card>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { getDate } from '@assets/js/dateUtils';
import { cloneDeep } from 'lodash';
import { fequAPI } from '@api/modules/fequ';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: '9008',
  components: { DynamicUTable },
  data() {
    return {
      dateRange: [],
      tableData: [],
      loading: true,
      tableProperties: [
        {
          label: '退回时间',
          prop: 'create_time',
          itemType: 'input',
          input: false,
          sortable: false,
          widthAuto: true,
          formatter: val => getDate(val, false)
        },
        { label: '委托单号', prop: 'requ_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '我司货号', prop: 'prod_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '更改号', prop: 'prod_suffix', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '产品名称', prop: 'prod_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '退回原因', prop: 'requ_reason', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '操作', prop: 'operation', itemType: 'input', input: false, sortable: false, widthAuto: true }
      ]
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.loading = true;
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      let [startTime, endTime] = this.dateRange || [];
      searchForm.startTime = startTime ?? null;
      searchForm.endTime = endTime ?? null;
      searchForm.stff_id = this.$cookies.get('userInfo').stff_id;
      fequAPI.getfequPush9008(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.loading = false;
      });
    },
    buttonRefresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.getTableData();
    },
    del(row) {
      fequAPI.delete9008({ id: row.id }).then(({ data }) => {
        this.$message.success('操作成功!');
        this.getTableData();
      });
    },
    jumpFequ(row) {
      this.jump('/requ_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: 114,
            form_id: row.form_id
          })
        )
      });
    }
  }
};
</script>

<style></style>
